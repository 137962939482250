const { createSlice } = require("@reduxjs/toolkit")

const InitialState = {
    credit_data: 0
}

const creditSlice = createSlice({
    name: "customer",
    initialState: {
        credit_data: 0
    },
    reducers: {
        addCredit: (state, action) => {
            const x = action.payload

            state.credit_data = (x);
        },
        erasecreditData: (state, action) => {
            state.credit_data = 0;
        }
    }
})

export const { addCredit, erasecreditData } = creditSlice.actions;

export default creditSlice.reducer;