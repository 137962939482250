const { createSlice } = require("@reduxjs/toolkit")
const InitialState = {
    customer: [],
    customerDetails: {
        cust_id: "",
        customer_name: "",
        mobile: "",
        address: "",
        email_id: "",
    }
}
const customerSlice = createSlice({
    name: "customer",
    initialState: {
        customer: InitialState.customer,
        customerDetails: InitialState.customerDetails
    },
    reducers: {
        search: (state, action) => {
            state.customer = (action.payload)
        },
        addCustomerDetails: (state, action) => {
            state.customerDetails = action.payload
        },
        removeCustomerDetails: (state, action) => {
            state.customerDetails = InitialState.customerDetails
        }
    }
})
export const { search, addCustomerDetails, removeCustomerDetails } = customerSlice.actions;
export default customerSlice.reducer;