const { createSlice } = require("@reduxjs/toolkit")

const InitialState = {
    cash: 0,
    notes: [
        { label: 2000, values: 0 },
        { label: 500, values: 0 },
        { label: 200, values: 0 },
        { label: 100, values: 0 },
        { label: 50, values: 0 },
        { label: 20, values: 0 },
        { label: 10, values: 0 },
        { label: 5, values: 0 },
        { label: 2, values: 0 },
        { label: 1, values: 0 }
    ]
}

const cashSlice = createSlice({
    name: "customer",
    initialState: {
        cash: 0,
        notes:InitialState.notes
    },
    reducers: {
        addCash: (state, action) => {
            const x = action.payload
            console.log(x);
            state.cash = (x.cash);
        },
        setNotes: (state, action) => {
            const x = action.payload;
            state.notes = x;
        },
        erasecashData:(state,action)=>{
            state.cash=0;
            state.notes=InitialState.notes
        }
    }
})

export const { addCash, setNotes,erasecashData } = cashSlice.actions;

export default cashSlice.reducer;