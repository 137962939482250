import { combineReducers } from '@reduxjs/toolkit'
import customer from "./dataSlice"
import product from './productSlice'
import cart from './cartSlice'
import discount from './billDiscount'
import deliveryAddress from './deliveryAddress'
import cash_data from './cashSlice'
import credit_data from './credit_slice'
import orderSlice from './orderSlice'
const reducer = combineReducers({
    customer,
    product,
    cart,
    discount,
    cash_data,
    credit_data,
    deliveryAddress,
    orderSlice
})

export default reducer






