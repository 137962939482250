import CryptoJS from 'crypto-js';

export const encryptData = (key, data) => {
  const encrypted = CryptoJS.AES.encrypt(data, key).toString();
  // localStorage.setItem(key, encrypted);
  // console.log({ data, key, encrypted });
  return encrypted;
}

export const decryptData = (data, key) => {
  // const data = localStorage.getItem(key);
    const decrypted = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
    // console.log({ data, key, decrypted });
    return JSON.parse(decrypted);

}

