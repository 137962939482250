const { createSlice } = require("@reduxjs/toolkit")

const InitialState = {
    deliveryAddress: [],
    selectedAddress: {},
}

const deliveryAddressSlice = createSlice({
    name: "deliveryAddress",
    initialState: {
        deliveryAddress: InitialState.deliveryAddress,
        selectedAddress: InitialState.selectedAddress
    },
    reducers: {
        addDeliveryAdd: (state, action) => {
            state.deliveryAddress = action.payload
        },
        removeDeliveryAdd: (state, action) => {
            console.log(action.payload);
            const addressId = action.payload;
            state.deliveryAddress = state.deliveryAddress.filter(
                (address) => address.id !== addressId
            );
        },
        selectAddress: (state, action) => {
            console.log(action.payload);
            const address = action.payload;
            state.selectedAddress = address
        },
        eraseAddress: (state, action) => {
            state.selectedAddress = {}
        }


    }
})

export const { addDeliveryAdd, removeDeliveryAdd, selectAddress,eraseAddress } = deliveryAddressSlice.actions;

export default deliveryAddressSlice.reducer;