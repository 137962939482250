const { createSlice } = require("@reduxjs/toolkit")

const InitialState = {
    product: [],

}

const productSlice = createSlice({
    name: "product",
    initialState: {
        product: InitialState.product,

    },
    reducers: {
        Allproduct: (state, action) => {

            state.product = (action.payload)
        },



    }
})

export const { Allproduct } = productSlice.actions;

export default productSlice.reducer;