import { createSlice } from '@reduxjs/toolkit'
import { USER_ENCRYPTION_KEY } from 'constants/app.constant';
import { encryptData } from 'utilities/Store_data';

// const user_data =

export const initialState = encryptData(USER_ENCRYPTION_KEY, JSON.stringify({
    avatar: '',
    userName: '',
    email: '',
    authority: []
}));

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer