import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: []
}

export const user_dummySlice = createSlice({
	name: 'auth/user_dummy',
	initialState,
	reducers: {
        setUser_dummy: (_, action) => action.payload,
        user_dummyLoggedOut: () => initialState,
	},
})

export const { setUser_dummy } = user_dummySlice.actions

export default user_dummySlice.reducer