import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import user_dummySlice from "./user_dummySlice"

const reducer = combineReducers({
    session,
    user,
    user_dummySlice
})

export default reducer