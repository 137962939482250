// const { Notification, toast } = require("components/ui")
import {Notification, toast} from "components/ui"
import { FaTrashAlt } from "react-icons/fa"
import { HiOutlineGift } from "react-icons/hi"

const OpenNotification = (type, message,del) => {
    toast.push(
        <Notification
            title={type=='danger'?'':type.charAt(0).toUpperCase() + type.slice(1)}
            customIcon={del=='delete'?<FaTrashAlt className="text-red-600" />:""}
            type={type}
        >
            {message}
        </Notification>,
        {
            placement: 'top-center',
        }
    )
}

export default OpenNotification