import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                // console.log({ param, response });
                const resp = { data: { body: response.data } };
                // console.log({ resp });
                resolve(resp)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export default ApiService