const { createSlice } = require("@reduxjs/toolkit")
const InitialState = {
    subtotal: 0,
    total: 0,
    discount: 0,
    discountType: true,
    discount_percent_value:0
}
const discountSlice = createSlice({
    name: "customer",
    initialState: {
        subtotal: InitialState.subtotal,
        total: InitialState.total,
        discount: InitialState.discount,
        discountType: InitialState.discountType,
        discount_percent_value:InitialState.discount_percent_value
    },
    reducers: {
        discount: (state, action) => {
            const x = action.payload
            state.subtotal = (x.subTotal)
            state.total = (x.total)
            state.discount = (x.discountval)
            state.discountType = (x.discountType)
            state.discount_percent_value=(x.discount_percent_value)
        },
        addDiscount: (state, action) => {
            const x = action.payload
            // console.log(x);
            state.subtotal = (x.subTotal)
            state.total = (x.total)
            state.discount = (x.discountval)
            state.discountType = (x.discountType)
            state.discount_percent_value=(x.discount_percent_value)
        },
        removeBillDiscount: (state, action) => {
            const x = action.payload
            // console.log(x);
            state.subtotal = InitialState.subtotal
            state.total = InitialState.total
            state.discount = InitialState.discount
            state.discountType = InitialState.discountType
            state.discount_percent_value=InitialState.discount_percent_value
        }
    }
})
export const { discount, addDiscount, removeBillDiscount } = discountSlice.actions;
export default discountSlice.reducer;